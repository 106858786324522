import React, { useEffect, useState } from "react";

const Register = ({ context }) => {

    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [tilakaName, setTilakaName] = useState('')

    const register = async () => {
        setLoading(true)
        setErrorMessage('')

        const x = await context.regId.requestDataset(tilakaName, 'ktp-el')
        if (x.code !== 200) {
            if (x.info.message === 'forbidden') {
                setErrorMessage('Mohon periksa lagi namanya ya, sepertinya salah')

            } else {
                setErrorMessage(x.info.message)
            }
        } else {
            context.setTilakaName(tilakaName)
            context.setRequest(x.info)
            context.setStep(1)
        }
    }

    const clickDisabled = () => {
        if (loading) return true
        return !tilakaName
    }

    return (<div>

        {errorMessage && <h5>{errorMessage}</h5>}
        <label>
            Tilaka Name: <input type="text" name="tilakaName" value={tilakaName} onChange={(e) => setTilakaName(e.target.value)} />
        </label>


        <br />
        <button onClick={register} disabled={!tilakaName}>Get Data</button>
    </div>)
}

export default Register;