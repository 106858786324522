import React from "react";
import Dataset from "../dataset/dataset";

const Authd1 = () => {
  return (
    <div className="page">
      <h1>Akses Dataset</h1>
      <Dataset />

    </div>
  );
};

export default Authd1;
