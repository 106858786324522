import { asn1, pkcs7, util } from "node-forge"

const styles = {
    card: {
        border: 'solid 1px #aaaaaa'
        , margin: '1em',
        padding: '1em',
        width: '500px'
    },
    fieldName: {
        width: '200px',
        display: 'block'
    },

    fieldValue: {
        fontWeight: 'bold',
        display: 'block',
        marginBottom: '0.5em',
        width: '450px',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}
const KTP = ({ data }) => {

    const signatures = []
    data.signatures.forEach(signature => {
        console.log(signature)

        try {
            const x = asn1.fromDer(atob(signature.cmsSignature))
            if (x) {
                const p7 = (pkcs7.messageFromAsn1(x))
                signatures.push({
                    certificates: p7.certificates,
                })

            }
        } catch (e) {
            signatures.push({ error: e.message })
        }
    })


    return (
        <div>
            {data?.data?.nama && <div><span style={styles.fieldName}>Nama</span><span style={styles.fieldValue}>{data.data.nama}</span></div>}
            {data?.data?.nik && <div><span style={styles.fieldName}>NIK</span><span style={styles.fieldValue}>{data.data.nik}</span></div>}
            {data?.data?.tanggalLahir && <div><span style={styles.fieldName}>Tanggal Lahir</span><span style={styles.fieldValue}>{data.data.tanggalLahir}</span></div>}
            {data?.data?.frData && <div><img src={'data:image/png;base64,' + data.data.frData} /></div>}
            {data?.data?.ktpelFile && <div><img src={'data:image/png;base64,' + data.data.ktpelFile} /></div>}
            <div><span style={styles.fieldName}>Tanda-tangan digital</span></div>

            {signatures.map((sig, i) => {
                console.log(sig.certificates)
                return (<div style={styles.card} key={i}>
                    <div ><span style={styles.fieldName}>Tanda-tangan #{i}</span></div>
                    {sig.error && (
                        <div><span style={styles.fieldName}>Error</span>
                            <span style={styles.fieldValue}>{sig.error}</span></div>

                    )}

                    {sig.certificates && sig.certificates.map((cert, j) => {
                        const issuerDn = []
                        cert.issuer.attributes.map(attr => {
                            issuerDn.push(attr.shortName + '=' + attr.value)
                        })
                        const subjectDn = []
                        cert.subject.attributes.map(attr => {
                            subjectDn.push(attr.shortName + '=' + attr.value)
                        })
                        return (
                            <div key={j}>
                                <div><span style={styles.fieldName}>Sertifikat #{j}</span></div>
                                <div><span style={styles.fieldName}>Seri</span><span style={styles.fieldValue}>{cert.serialNumber}</span></div>
                                <div><span style={styles.fieldName}>TTD</span><span style={styles.fieldValue}>{util.bytesToHex(cert.signature)}</span></div>
                                <div><span style={styles.fieldName}>Penandatangan</span><span style={styles.fieldValue}>{subjectDn.reverse().join(',')}</span></div>

                                <div><span style={styles.fieldName}>Issuer</span><span style={styles.fieldValue}>{issuerDn.reverse().join(',')}</span></div>
                                <div><span style={styles.fieldName}>Masa waktu</span><span style={styles.fieldValue}>{'' + cert.validity.notBefore}-{'' + cert.validity.notAfter}</span></div>

                            </div>
                        )
                    }
                    )}
                </div>
                )
            })}
        </div>
    )
}

export default KTP