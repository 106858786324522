import React from "react";

const Page2 = () => {

  return (
    <div className="page">
      <p>
        Fusce sed laoreet purus. Quisque urna sapien, eleifend quis metus eu,
        posuere cursus est. Pellentesque hendrerit risus quis mi iaculis
        scelerisque. Sed vel ultrices nisi, eget molestie tellus. Nulla ultrices
        nisl et arcu tempor varius id quis metus. Curabitur imperdiet lectus
        arcu, ut aliquam nisi maximus id. Proin et massa ac nibh convallis
        pellentesque. Nulla feugiat velit eget enim mollis dapibus. Quisque
        blandit ligula sed purus dictum gravida.
      </p>
      <p>
        Etiam porta nunc at augue feugiat porta. Maecenas quis placerat tortor.
        Maecenas dignissim faucibus feugiat. Curabitur scelerisque et ex sed
        vehicula. Sed efficitur lectus egestas ex mattis, vitae feugiat est
        porttitor. Aliquam convallis mauris auctor consequat condimentum. Etiam
        sagittis lacinia felis, in elementum sem. Nam tristique libero turpis.
        Donec sit amet ultrices eros. Proin id porta lacus, sed ornare velit.
        Nullam eleifend, lectus et dapibus euismod, risus mauris suscipit lacus,
        quis tincidunt tortor lacus a ante. Ut volutpat in nisl ac pharetra.
        Vestibulum molestie consequat nisi a convallis. Curabitur tortor ex,
        luctus at mauris vitae, gravida suscipit ante. Morbi accumsan malesuada
        mauris, non congue velit tincidunt ut.
      </p>
      <p>
        Integer ultrices lorem et aliquet aliquam. Ut accumsan mattis dui, ut
        ornare dolor interdum sed. Pellentesque habitant morbi tristique
        senectus et netus et malesuada fames ac turpis egestas. Maecenas
        tincidunt et diam vel cursus. Donec euismod odio sed neque egestas, non
        placerat sem tincidunt. Mauris quis nulla quis augue sodales viverra id
        quis justo. Sed non nibh vel libero pretium facilisis ac sit amet erat.
        Maecenas sollicitudin sollicitudin ligula, ut consectetur augue
        tincidunt quis. Maecenas feugiat at lorem non tempus. Morbi nec ante
        lectus. Donec nec quam nunc. Nulla euismod, ante sed molestie hendrerit,
        magna velit molestie est, et efficitur elit magna a nisi. Pellentesque
        convallis lectus non ligula iaculis lobortis. Integer semper faucibus
        elementum. Vivamus diam sapien, convallis quis enim quis, sollicitudin
        aliquam sapien.
      </p>
    </div>
  );
};

export default Page2;
