import React, { useEffect, useState } from "react"

const Pending = ({ context }) => {
    const [status, setStatus] = useState('')

    const check = async () => {
        setStatus('')
        const ret = await context.regId.pollDataset(context.request.request_token, context.request.request_id)
        if (ret.code === 200) {
            if (!ret.info.access_token) {
                setStatus('Belum disetujui')
                return
            }
            if (ret.info.access_token === 'not-authorized') {
                setStatus('Tidak disetujui')
                context.setStep(4)

                return
            }
            context.setAccessToken(ret.info.access_token)
            context.setStep(2)
        } else {
            setStatus('Permintaan dataset tidak dapat diproses')
        }
    }




    useEffect(async () => {
        //await check()
    })

    return (
        <div>
            <h5>Menunggu otorisasi dari pemilik akun...</h5>

            {status && <h5>{status}</h5>}

            <button onClick={check}>Cek lagi sekarang</button>
            <div>
                <button onClick={context.reset}>Ke halaman depan</button>
            </div>
        </div>

    )
}

export default Pending