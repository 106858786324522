import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigation } from "react-navi";

const NavBar = () => {
  const { keycloak, initialized } = useKeycloak();
  const nav = useNavigation();
  return (
    <header>
      <div>BANK ABC</div>

      <div className="menu">
        <div className="left-button" onClick={() => nav.navigate("/page1")}>
          Page 1
        </div>

        <div className="left-button" onClick={() => nav.navigate("/page2")}>
          Page 2
        </div>
        <div className="left-button" onClick={() => nav.navigate("/authd1")}>
          [Secured 1]
        </div>
        <div className="left-button" onClick={() => nav.navigate("/authd2")}>
          [Secured 2]
        </div>

      </div>
      {!keycloak.authenticated && (
        <div className="left-button" onClick={() => keycloak.login()}>
          LOGIN
        </div>
      )}

      {!!keycloak.authenticated && (
        <div className="left-button" onClick={() => keycloak.logout()}>
          LOGOUT
        </div>
      )}
    </header>
  );
};

export default NavBar;
