class TilakaRegID {
    constructor(regIdUrl, iamClientID, iamClientSecret, realm) {
        this.regIdUrl = regIdUrl
        this.iamClientID = iamClientID
        this.iamClientSecret = iamClientSecret
        this.realm = realm
    }

    setDatasetToken(token) {
        this.datasetToken = token
    }

    isDatasetAuthenticated() {
        return this.datasetToken !== undefined
    }

    isIamAuthenticated() {
        return this.iamToken !== undefined
    }

    getHeaders() {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
    }

    getHeadersIamAuth() {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.iamToken
        };
    }

    getHeadersDatasetAuth() {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Tilaka-Dataset': this.datasetToken,
            'Authorization': 'Bearer ' + this.iamToken
        };
    }

    async baseRequest(path, verb, body, headers) {

        const basicHeaders = this.isIamAuthenticated() ?
            (this.isDatasetAuthenticated() ? this.getHeadersDatasetAuth() : this.getHeadersIamAuth())
            : this.getHeaders()

        console.log(basicHeaders, `${this.regIdUrl}${path}`)

        const request = {
            method: verb,
            headers: {
                ...basicHeaders,
                ...headers
            }
        }
        if (body) {
            request.body = JSON.stringify(body)
        }
        const response = await fetch(`${this.regIdUrl}${path}`, request)
        if (response.status == 200) {
            return { message: 'success', code: 200, isSuccessful: true, info: await response.json() }
        }
        return { info: await response.json(), code: response.status, isSuccessful: false }
    }

    getRequest(path, headers) {
        return this.baseRequest(path, 'GET', null, headers || {});
    }

    postRequest(path, body, headers) {
        return this.baseRequest(path, 'POST', body, headers || {});
    }

    getDatasetRequestInfo(requestId, requestToken) {
        return this.getRequest('/api/v1/dataset/' + requestToken, {
            'X-Tilaka-Dataset-Request-Id': requestId
        });
    }

    getDataset(tilakaName, datasetType) {
        return this.getRequest('/api/v1/dataset/' + tilakaName + '/' + datasetType, {});
    }

    subscribePreAuth(token, cb) {
        const me = this;
        this.socket = new WebSocket(this.serverURL.replace("https:", "wss:") + "/api/v1/dataset/subscribe-update/" + token);

        this.socket.onopen = function (e) {
            console.log("WS connection established");

        };

        this.socket.onmessage = async function (event) {

            if (event.data !== "timeout") {
            } else {
                if (cb) {
                    cb(event.data)
                }
            }
        };

        this.socket.onclose = function (event) {
            if (event.wasClean) {
                console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
            } else {
                console.log('[close] Connection died');
            }
        };

        this.socket.onerror = function (error) {
            console.log(`[error] ${error.message}`);
        };
    }

    urlEncodeRequest(details) {
        var formBody = [];
        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        return formBody
    }

    async iamLoginRequest() {
        if (!this.iamLoginUrl) {
            const step = await this.getLoginUrl()
            if (!step || !step.isSuccessful) {
                return step
            }
        }
        const response = await fetch(`${this.iamLoginUrl}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: this.urlEncodeRequest({
                client_id: this.iamClientID,
                client_secret: this.iamClientSecret,
                grant_type: 'client_credentials',
                scope: 'openid',
            })
        })

        if (response.status == 200) {
            const token = await response.json()
            if (token.access_token) {
                this.iamToken = token.access_token
                return { message: token.access_token, code: 200, isSuccessful: true }
            }
        }

        return { info: await response.json(), code: response.status, isSuccessful: false }
    }

    async getLoginUrl() {
        const iamUrlInfo = await this.getRequest(`/public/iam/login-url/${this.realm}`)

        if (iamUrlInfo && iamUrlInfo.isSuccessful) {
            this.iamLoginUrl = iamUrlInfo.info.message
        }
        return iamUrlInfo

    }

    async requestDataset(tilakaName, datasetType) {
        return this.postRequest('/api/v1/dataset/request', {
            tilaka_name: tilakaName,
            dataset_type: datasetType
        })
    }

    async pollDataset(requestToken, requestId) {
        return this.getRequest('/api/v1/dataset/' + requestToken, {
            'X-Tilaka-Dataset-Request-Id': requestId,
        })
    }

    async getDataset(tilakaName, datasetType) {
        return this.getRequest(`/api/v1/dataset/${tilakaName}/${datasetType}`)
    }
}

window.t = TilakaRegID

export default TilakaRegID;