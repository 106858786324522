import React, { useEffect, useState } from "react";
import TilakaRegID from "../TilakaRegID";
import Denied from "./denied";
import Display from "./display";
import Pending from "./pending";
import Register from "./register";
import { canonicalize } from 'json-canonicalize';


const Dataset = () => {


    const [step, setStep] = useState(0)
    const [request, setRequest] = useState({})
    const [accessToken, setAccessTokenState] = useState(undefined)
    const [tilakaName, setTilakaName] = useState()
    const [regId] = useState(new TilakaRegID(process.env.REACT_APP_REGID_URL,
        process.env.REACT_APP_KEYCLOAK_CLIENT_ID_DATASET, process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET,
        process.env.REACT_APP_KEYCLOAK_REALM)
    )

    const reset = () => {
        context.setStep(0)
    }

    const setAccessToken = (accessToken) => {
        regId.setDatasetToken(accessToken)
        setAccessTokenState(accessToken)
    }

    const context = {
        regId,
        request,
        setRequest,
        accessToken,
        setAccessToken,
        step,
        setStep,
        reset,
        tilakaName,
        setTilakaName
    }
    const s = useEffect(async () => {
        await regId.iamLoginRequest()
    })
    return (<div>
        <h3>Data Set</h3>
        {step === 0 && <Register context={context} ></Register>}
        {step === 1 && <Pending context={context} ></Pending>}
        {step === 2 && <Display context={context} ></Display>}
        {step === 4 && <Denied context={context} ></Denied>}

    </div>)
}

export default Dataset;