import logo from './logo.svg';
import './App.css';

import Keycloak from 'keycloak-js'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { AppRouter } from './routes'
import NavBar from './NavBar';
import TilakaRegID from './TilakaRegID';

const keycloak = new Keycloak({
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
})
const keycloakProviderInitConfig = {
  onLoad: 'login-required'
}
function App() {
  const onKeycloakEvent = (event, error) => {
    console.log('onKeycloakEvent', event, error)
  }

  const onKeycloakTokens = (tokens) => {
    console.log('onKeycloakTokens', tokens)
  }

  window.t = TilakaRegID
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initConfig={keycloakProviderInitConfig}
      onEvent={onKeycloakEvent}
      onTokens={onKeycloakTokens}
    >
      <AppRouter>

      </AppRouter>
    </ReactKeycloakProvider>
  );
}

export default App;
