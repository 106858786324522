import React, { useEffect, useState } from "react";
import KTP from "./ktp";

const Display = ({ context }) => {
    const [dataset, setDataset] = useState()
    const [errorMessage, setErrorMessage] = useState()
    const getDataset = async () => {
        const ret = await context.regId.getDataset(context.tilakaName, 'ktp-el')
        if (ret.code === 200) {
            console.log(ret)
            setDataset(ret.info)
        } else {
            setErrorMessage(ret.code)
        }
    }

    useEffect(async () => {
        await getDataset()
    }, [])

    return (
        <div>
            {errorMessage && (<div>Ada kesalahan akses, kode: {errorMessage}</div>)}
            <div style={{ maxWidth: '200px' }}>
                {dataset && dataset.map(e => (<KTP data={e} />))}
            </div>
            <div>
                <button onClick={context.reset}>Ke halaman depan</button>
            </div>
        </div>
    )
}

export default Display;