import React from "react";

const Denied = ({ context }) => {


    return (
        <div>
            <div>
                <p>Permintaan Anda untuk mengakses e-KTP milik {context.tilakaName} ditolak</p>
                <button onClick={context.reset}>Ke halaman depan</button>
            </div>
        </div>
    )
}

export default Denied;