import React from "react";

const Page1 = () => {

  return (
    <div className="page">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus quis
        nunc sit amet dolor ultricies imperdiet. Proin maximus felis at ligula
        consequat, ac tincidunt ligula facilisis. Curabitur egestas venenatis
        justo quis placerat. In urna ligula, semper eget facilisis ut, malesuada
        ac dui. Aenean malesuada, dolor in malesuada accumsan, eros ex
        ullamcorper nisi, quis accumsan quam eros et lacus. Morbi sit amet
        dictum felis. Pellentesque tortor enim, laoreet nec magna vel,
        scelerisque faucibus sapien. Lorem ipsum dolor sit amet, consectetur
        adipiscing elit. Vestibulum accumsan tortor vitae enim pretium
        tincidunt.
      </p>

      <p>
        Maecenas elementum et orci nec condimentum. Donec dapibus, nunc id
        imperdiet interdum, lectus dui ornare ipsum, vitae consectetur quam
        felis vehicula tellus. Nunc leo nibh, hendrerit lacinia ornare sit amet,
        viverra efficitur nulla. Nulla tincidunt non enim non gravida. Quisque
        ornare, magna quis ultrices pretium, nisl risus ornare nibh, at
        condimentum ante eros ut ipsum. Nulla dolor diam, varius eget porta nec,
        luctus vitae ex. Suspendisse potenti. Donec quam lorem, consectetur at
        mauris at, consectetur lacinia erat.
      </p>
      <p>
        Pellentesque placerat elit eget quam porta pretium. Pellentesque
        sollicitudin mi facilisis odio porttitor rhoncus. Quisque consectetur
        elit euismod vulputate gravida. Donec non ullamcorper elit. Nullam
        consectetur velit libero. Morbi vehicula mollis nisi id consequat.
        Vestibulum rhoncus felis quis sapien hendrerit tincidunt. Nunc ut lectus
        id nisi fringilla rhoncus a ac arcu. Phasellus molestie felis eu velit
        feugiat, sed condimentum arcu laoreet. Phasellus a tellus quis nibh
        tincidunt tempor ut scelerisque lectus. Proin viverra elit eu turpis
        sollicitudin, quis laoreet quam pulvinar. Phasellus volutpat consequat
        enim eget convallis. Aenean in mattis ante. Donec condimentum, quam quis
        pretium porttitor, eros sapien lacinia lacus, sed condimentum dolor odio
        non diam.
      </p>
    </div>
  );
};


export default Page1;