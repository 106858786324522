import { useKeycloak } from "@react-keycloak/web";
import React from "react";

const AuthWall = () => {
  const { keycloak } = useKeycloak();
  return (
    <div className="page">
      <h1>Halo</h1>
      <p>
        Silakan <button onClick={() => keycloak.login()}>Login Dulu</button>{" "}
        untuk melanjutkan.
      </p>
    </div>
  );
};

export default AuthWall;