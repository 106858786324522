import React, { Suspense } from "react";
import { Router, View } from "react-navi";
import { useKeycloak } from "@react-keycloak/web";
import { mount, route, lazy, map, redirect } from "navi";

import HomePage from "../pages/home";

import { withAuthentication } from "./utils";
import Page1 from "../pages/page1";
import NavBar from "../NavBar";
import Page2 from "../pages/page2";
import Authd1 from "../pages/authd1";
import Authd2 from "../pages/authd2";
import AuthWall from "../pages/authd";

// Define your routes
const routes = mount({
  "/home": withAuthentication(
    route({
      title: "Home",
      view: <HomePage />,
    })
  ),
  "/login": map(async (request, context) =>
    context.isAuthenticated
      ? redirect(
          request.params.redirectTo
            ? decodeURIComponent(request.params.redirectTo)
            : "/home"
        )
      : lazy(() => import("../pages/login"))
  ),
  "/page1": route((req) => ({
    view: <Page1 />,
  })),
  "/page2": route((req) => ({
    view: <Page2 />,
  })),

  "/authd1": route((req,ctx) => ctx.isAuthenticated ? ({
    view: <Authd1 />,
  }): ({view:<AuthWall/>})),

  "/authd2": route((req,ctx) => ctx.isAuthenticated ? ({
    view: <Authd2 />,
  }): ({view:<AuthWall/>})),

  "/": redirect("/home"),
});

export const AppRouter = () => {
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return (
      <div>
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <Router
      routes={routes}
      context={{ keycloak, isAuthenticated: keycloak.authenticated }}
    >
      <NavBar />

      <Suspense fallback={null}>
        <View />
      </Suspense>
    </Router>
  );
};
