import React from "react";

const Authd2 = () => {
  return (
    <div className="page">
      <h1>Secured Page 2</h1>
      <p>
        Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer id
        ultricies neque, non egestas eros. Suspendisse convallis odio a augue
        egestas, vel placerat mi faucibus. Maecenas ac dui vestibulum, posuere
        mauris a, malesuada elit. Aliquam sit amet suscipit metus. Nulla luctus
        egestas erat. Fusce sodales suscipit arcu a gravida. Aenean sollicitudin
        ligula eget neque scelerisque gravida. Sed maximus pulvinar metus sed
        vestibulum. Nullam non commodo nunc. Quisque vitae odio magna. Nullam
        sit amet lacinia justo, accumsan lacinia dui. Vivamus consequat
        convallis est, nec accumsan quam fringilla et. Pellentesque at vulputate
        tellus, eu pellentesque sapien. Nunc suscipit augue et diam commodo, et
        accumsan sapien molestie.
      </p>
      <p>
        Etiam porta nunc at augue feugiat porta. Maecenas quis placerat tortor.
        Maecenas dignissim faucibus feugiat. Curabitur scelerisque et ex sed
        vehicula. Sed efficitur lectus egestas ex mattis, vitae feugiat est
        porttitor. Aliquam convallis mauris auctor consequat condimentum. Etiam
        sagittis lacinia felis, in elementum sem. Nam tristique libero turpis.
        Donec sit amet ultrices eros. Proin id porta lacus, sed ornare velit.
        Nullam eleifend, lectus et dapibus euismod, risus mauris suscipit lacus,
        quis tincidunt tortor lacus a ante. Ut volutpat in nisl ac pharetra.
        Vestibulum molestie consequat nisi a convallis. Curabitur tortor ex,
        luctus at mauris vitae, gravida suscipit ante. Morbi accumsan malesuada
        mauris, non congue velit tincidunt ut.
      </p>
      <p>
        Integer ultrices lorem et aliquet aliquam. Ut accumsan mattis dui, ut
        ornare dolor interdum sed. Pellentesque habitant morbi tristique
        senectus et netus et malesuada fames ac turpis egestas. Maecenas
        tincidunt et diam vel cursus. Donec euismod odio sed neque egestas, non
        placerat sem tincidunt. Mauris quis nulla quis augue sodales viverra id
        quis justo. Sed non nibh vel libero pretium facilisis ac sit amet erat.
        Maecenas sollicitudin sollicitudin ligula, ut consectetur augue
        tincidunt quis. Maecenas feugiat at lorem non tempus. Morbi nec ante
        lectus. Donec nec quam nunc. Nulla euismod, ante sed molestie hendrerit,
        magna velit molestie est, et efficitur elit magna a nisi. Pellentesque
        convallis lectus non ligula iaculis lobortis. Integer semper faucibus
        elementum. Vivamus diam sapien, convallis quis enim quis, sollicitudin
        aliquam sapien.
      </p>
    </div>
  );
};

export default Authd2;
